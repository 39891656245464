import React from "react"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Card from "../components/Card/Card"

// Image Asset Imports
import imageYamahaSmall from "../images/home/browser_yamaha_small.png"
import imageUHCSmall from "../images/work/uhc/UHC_DesignSystem_Small.png"
import imageWahlSmall from "../images/home/browser_wahl_small.png"
import imageYamahaLarge from "../images/home/browser_yamaha.png"
import imageUHCLarge from "../images/work/uhc/UHC_DesignSystem.png"
import imageWahlLarge from "../images/home/browser_wahl.png"

const Work = props => (
  <Layout>
    <SEO title="Work" description="Digital projects that I've worked on over the past few years." />
    <div className="mt-16 mb-12">
      <h1 className="">Work</h1>
      <p className="mb-12">These are just a few of the fun things I’ve worked on.</p>
    </div>

  <div>

  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-x-10 gap-y-20 lg:gap-y-16 mt-8 mb-8">
      <Card
        title={"UnitedHealthcare"}
        image={imageUHCSmall}
        zoomImage={imageUHCLarge}
        imageBGColor="pink"
        link={{ title: "View Case Study", destination: "/work/unitedhealthcare" }}
        subtitle="Web Application & Design System"
      >
        <p>
        Rebuilding a 30-year old legacy system from the ground-up is no small task, nor is developing a design system alongside it. My grey hairs have doubled, but at least you get some fun stories out of it.
        </p>
      </Card>

      <Card
        title={"Yamaha"}
        image={imageYamahaSmall}
        zoomImage={imageYamahaLarge}
        imageBGColor="orange"
        link={{ title: "View Case Study", destination: "/work/yamaha-outboards"}}
        subtitle="Consumer Website (Agency)"
      >
        <p>
        A media-rich consumer website to excite prospective boaters and provide resources to existing owners. A labor of love made by the most amazing digital team this side of Milwaukee.
        </p>
      </Card>

      <Card
        title={"Wahl"}
        image={imageWahlSmall}
        zoomImage={imageWahlLarge}
        imageBGColor="green"
        link={{ title: "View Case Study", destination: "/work/wahl" }}
        subtitle="Consumer Website (Agency)"

      >
        <p>
        An eCommerce website unifying three websites into a single, scalable platform. Information architecture was the name of the game, along with building a simple and effective shopping experience.
        </p>
      </Card>
    </div>


  </div>


    
  </Layout>
)

export default Work
