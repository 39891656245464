import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const LinkButtonWrapper = props => {
  return (
    <div>
      {props.type === "internal" ? (
        <Link
          className="py-4 px-6 inline-block w-auto font-semibold bg-primary-black cursor-pointer hover:bg-primary-hover transition duration-75 ease-in no-underline"
          to={props.destination}
        >
          {props.children}
        </Link>
      ) : (
        <a
          className="py-4 px-6 inline-block w-auto font-semibold bg-primary-black cursor-pointer hover:bg-primary-hover transition duration-75 ease-in no-underline"
          href={props.destination}
        >
          {props.children}
        </a>
      )}
    </div>
  )
}

LinkButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  destination: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default LinkButtonWrapper
