import React from "react"
import PropTypes from "prop-types"

import ImageBlock from "../ImageBlock/ImageBlock"
import LinkButton from "../LinkButton/LinkButton"

const Card = props => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 ">
      <div className="mb-6">
        <ImageBlock color={props.imageBGColor} image={props.image} zoomImage={props.zoomImage} />
      </div>
      <div>
        <h3 className="mb-1">{props.title}</h3>
        <p className="subtitle mb-3">{props.subtitle}</p>
        {props.children}
        <div className="mt-8">
          <LinkButton
            destination={props.link.destination}
            text={props.link.title}
          ></LinkButton>
        </div>
      </div>
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.exact({
    title: PropTypes.string,
    destination: PropTypes.string,
  }),
  image: PropTypes.string,
  zoomImage: PropTypes.string,
  imageBGColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  
}

export default Card
