import React from "react"
import PropTypes from "prop-types"
import LinkButtonWrapper from "./LinkButtonWrapper/LinkButtonWrapper"

const LinkButton = props => {
  return (
    <LinkButtonWrapper destination={props.destination} type={props.type}>
      <div className="flex items-center text-white">
        {props.icon}
        <span>{props.text}</span>
      </div>
    </LinkButtonWrapper>
  )
}

LinkButton.propTypes = {
  destination: PropTypes.string.isRequired,
  icon: PropTypes.element,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default LinkButton
